import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiKey, useAuth } from '../../features/auth';
import { ProgressBar } from 'primereact/progressbar';
import { Image } from 'primereact/image';
import { useLazyCheckTokenValidityQuery } from '../../app/api/hubspot';
import { Skeleton } from 'primereact/skeleton';
import './style.css';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/authSlice';

const UNAUTHORIZED_REDIRECT_PATH = '/unauthorized';

type LoginStatus = {
  label: string;
  value: number;
};

const Login = () => {
  const { login } = useAuth();
  const apiKey = useApiKey();
  const dispatch = useDispatch();
  const [checkTokenValidity] = useLazyCheckTokenValidityQuery();
  const { state, search } = useLocation();
  const [loadingProgress, setLoadingProgress] = useState<LoginStatus>({ label: 'Loading...', value: 0 });
  const { redirectTo } = state || {};
  const navigate = useNavigate();
  const isLoggingInRef = useRef<boolean>(false);

  const redirectLogin = useCallback(() => {
    navigate(redirectTo || '/templates');
  }, [navigate, redirectTo]);

  const redirectApiKey = useCallback(() => {
    navigate('/api-key', { state: { redirectTo: redirectTo || '/templates' } });
  }, [navigate, redirectTo]);

  const redirectAuthorize = useCallback(() => {
    return navigate('/authorize');
  }, [navigate]);

  const redirectNoAuth = useCallback(() => {
    navigate(UNAUTHORIZED_REDIRECT_PATH);
  }, [navigate]);

  const doLogin = useCallback(async () => {
    try {
      isLoggingInRef.current = true;

      // Login
      setLoadingProgress({ label: 'Logging in', value: 68 });
      const params = new URLSearchParams(search);
      const queryToken = params.get('session_token');
      const user = await login(queryToken);
      if (!user) redirectNoAuth();
      dispatch(setUser(user));

      // Validate Scopes
      setLoadingProgress({ label: 'Checking scopes', value: 84 });
      const tokenValidity = await checkTokenValidity();
      // if token doesn't have the correct scopes, redirect to authorize
      if (tokenValidity?.data?.error) {
        return redirectAuthorize();
      }

      // Validate the API Key
      setLoadingProgress({ label: 'Validating API Key', value: 98 });
      const { error: apiKeyError, data: apiKeyData } = await apiKey.checkValidity({});
      if (apiKeyError || apiKeyData.isValid === false) return redirectApiKey();

      setLoadingProgress({ label: 'Login successful', value: 100 });
      redirectLogin();
    } catch (error) {
      return redirectNoAuth();
    }
  }, [
    login,
    dispatch,
    setLoadingProgress,
    search,
    redirectNoAuth,
    apiKey,
    checkTokenValidity,
    redirectAuthorize,
    redirectApiKey,
    redirectLogin,
  ]);

  useEffect(() => {
    if (!isLoggingInRef.current) doLogin();
  }, [doLogin, redirectLogin]);


  return (
    <div
      className='w-full h-screen flex justify-center items-center bg-primary-50'
      style={{ borderRadius: '20%', boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 1)' }}
    >
      <div className='text-center flex flex-col items-center gap-8' style={{ minWidth: '300px' }}>
        <div className='flex justify-center items-center'>
          <Image src='./images/logo.svg' width='180px' />
        </div>

        <ProgressBar
          value={loadingProgress?.value}
          showValue={false}
          style={{ height: '6px', width: '160px' }}
          pt={{
            root: {
              style: {
                backgroundColor: 'white',
              },
            },
            value: {
              className: 'p-skeleton',
            },
          }}
        ></ProgressBar>
        {/* Needed to bring in skeleton styles */}
        <Skeleton height='0px' />
      </div>
    </div>
  );
};

export default Login;
